import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { FormsModule } from '@angular/forms';

import { SharedModule } from '../shared/shared.module';
import { AuthComponent } from './auth.component';
import { EffectsModule } from '@ngrx/effects';
import { AuthEffects } from './store/auth.effects';
import { StoreModule } from '@ngrx/store';
import { authReducer } from './store/auth.reducer';

const authRoutes: Routes = [{ path: '', component: AuthComponent }];

@NgModule({
    declarations: [AuthComponent],
    imports: [
        SharedModule,
        FormsModule,
        RouterModule.forChild(authRoutes),
        EffectsModule.forFeature([AuthEffects]),
        StoreModule.forFeature('auth', authReducer),
    ],
    providers: [],
})
export class AuthModule {}

import { environment } from '../../../environments/environment';
import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { AuthResponseData, SignInTSO } from '../auth-response-data.model';
import { Observable } from 'rxjs';
import { AppState } from '../../app.reducer';
import { Store } from '@ngrx/store';
import { AuthActions } from '../store';

const SERVER_API_URL = environment.apiUrl;
const LOGIN_WITH_PASSWORD_URL = `${SERVER_API_URL}/tools/sign-in/with-password`;
const LOGIN_WITH_COOKIE_URL = `${SERVER_API_URL}/tools/sign-in/with-cookie`;
const REFRESH_TOKEN_URL = `${SERVER_API_URL}/tools/session/refresh`;

@Injectable({
    providedIn: 'root',
})
export class AuthHttpService {
    private tokenExpTimer: any;

    constructor(private http: HttpClient, private store: Store<AppState>) {}

    sendLoginData(creds: SignInTSO): Observable<AuthResponseData> {
        return this.http.post<AuthResponseData>(LOGIN_WITH_PASSWORD_URL, creds);
    }

    refreshSession(): Observable<AuthResponseData> {
        return this.http.get<AuthResponseData>(REFRESH_TOKEN_URL);
    }

    autoLogin(cookie: string): Observable<AuthResponseData> {
        return this.http.post<AuthResponseData>(LOGIN_WITH_COOKIE_URL, { cookie });
    }

    handleTimer(userData: AuthResponseData): void {
        this.setRefreshTimer(
            // TEST_TIMER_LENGTH,
            Math.floor(userData.refresh_token_seconds / 5) * 1000,
        );
    }

    setRefreshTimer(expirationDuration: number): void {
        this.clearRefreshTimer();
        this.tokenExpTimer = setInterval(() => {
            try {
                this.store.dispatch(AuthActions.refreshSession());
            } catch (e) {
                console.log('an error happened');
            }
        }, expirationDuration);
    }

    clearRefreshTimer(): void {
        if (this.tokenExpTimer) {
            clearInterval(this.tokenExpTimer);
            this.tokenExpTimer = null;
        }
    }
}

import {
    Directive,
    Input,
    TemplateRef,
    ViewContainerRef,
    OnDestroy,
} from '@angular/core';
import { Subscription } from 'rxjs';
import { select, Store } from '@ngrx/store';
import { AppState } from '../../app.reducer';
import { selectAuthState } from '../../auth/store/auth.reducer';

@Directive({
    selector: '[appHasAnyPermission]',
})
export class HasAnyPermissionDirective implements OnDestroy {
    private permissions: string[] = [];
    private sub$: Subscription;

    constructor(
        private store: Store<AppState>,
        private templateRef: TemplateRef<any>,
        private viewContainerRef: ViewContainerRef,
    ) {}

    @Input()
    set appHasAnyPermission(value: string | string[]) {
        this.permissions = typeof value === 'string' ? [value] : value;
        this.updateView([]);

        this.sub$ = this.store
            .pipe(select(selectAuthState))
            .subscribe((authState) => {
                this.updateView(authState?.user?.permissions || []);
            });
    }

    ngOnDestroy(): void {
        this.sub$?.unsubscribe();
    }

    private updateView(userPerms: string[] | null): void {
        const hasAnyPermission = this.hasAnyPermission(userPerms);
        this.viewContainerRef.clear();
        if (hasAnyPermission) {
            this.viewContainerRef.createEmbeddedView(this.templateRef);
        }
    }

    hasAnyPermission(userPerms: string[] | null): boolean {
        let neededPermissions = this.permissions;
        if (!Array.isArray(neededPermissions)) {
            neededPermissions = [neededPermissions];
        }
        return userPerms.some((permission: string) =>
            neededPermissions.includes(permission),
        );
    }
}

// import {
//     Directive,
//     Input,
//     TemplateRef,
//     ViewContainerRef,
//     OnDestroy,
// } from '@angular/core';
// import { Subscription } from 'rxjs';
//
// import { MemberAccountService } from '../../core/services/member-account.service';
//
// @Directive({
//     selector: '[appHasAnyPermission]',
// })
// export class HasAnyPermissionDirective implements OnDestroy {
//     private permissions: string[] = [];
//     private authenticationSubscription?: Subscription;
//
//     constructor(
//         private accountService: MemberAccountService,
//         private templateRef: TemplateRef<any>,
//         private viewContainerRef: ViewContainerRef,
//     ) {}
//
//     @Input()
//     set appHasAnyPermission(value: string | string[]) {
//         this.permissions = typeof value === 'string' ? [value] : value;
//         this.updateView();
//
//         this.authenticationSubscription = this.accountService
//             .getAuthenticationState()
//             .subscribe(() => this.updateView());
//     }
//
//     ngOnDestroy(): void {
//         if (this.authenticationSubscription) {
//             this.authenticationSubscription.unsubscribe();
//         }
//     }
//
//     private updateView(): void {
//         const hasAnyPermission = this.accountService.hasAnyPermission(this.permissions);
//         this.viewContainerRef.clear();
//         if (hasAnyPermission) {
//             this.viewContainerRef.createEmbeddedView(this.templateRef);
//         }
//     }
// }

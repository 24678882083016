import { Component, OnInit } from '@angular/core';
import { Observable } from 'rxjs';
import { select, Store } from '@ngrx/store';
import { selectAuthUser, selectIsAuthedState } from '../../auth/store/auth.reducer';
import { AppState } from '../../app.reducer';
import { AuthActions } from '../../auth/store';
import { User } from '../../auth/user.model';

@Component({
    selector: 'app-header-nav',
    templateUrl: './header-nav.component.html',
    styles: [
        `
            .nav-logo {
                width: 190px;
            }
        `,
    ],
})
export class HeaderNavComponent implements OnInit {
    isCollapsed: boolean;

    isAuthed$: Observable<boolean>;
    user$: Observable<User>;

    constructor(private store: Store<AppState>) {}

    ngOnInit(): void {
        this.isAuthed$ = this.store.pipe(select(selectIsAuthedState));
        this.user$ = this.store.pipe(select(selectAuthUser));
    }

    logout(): void {
        this.store.dispatch(AuthActions.logout());
    }
}

import { User } from '../user.model';
import { Router } from '@angular/router';
import { Actions, ofType, createEffect } from '@ngrx/effects';
import { switchMap, catchError, map, tap } from 'rxjs/operators';
import { of } from 'rxjs';
import { Injectable } from '@angular/core';
import { AuthResponseData } from '../auth-response-data.model';
import { BrowserStorageService } from '../../core/services/browser-storage.service';
import { AuthActions } from './index';
import { AuthHttpService } from '../services/auth-http.service';

// const TEST_TIMER_LENGTH = 1000;

/* ** STATUS 422 UNPROCESSABLE ENTITY **
// {
//     "code": "session-not-found",
//     "message": "The session token was NOT found.",
//     "attributes": {
//          "part": "session"
//      }
// }
 */

export type AuthResponseError = {
    error: {
        code: string;
        message: string;
        attributes: {
            part: string;
        };
    };
};

const handleAuthError = (errorRes: AuthResponseError | null) => {
    let errorMessage = 'An unknown error has occured.';
    if (!errorRes || !errorRes.error) {
        return of(AuthActions.authenticateFail({ errorMessage }));
    }
    const { code, message } = errorRes.error;
    switch (code) {
        case 'session-not-found':
            errorMessage = `Login Expired: ${message}`;
            break;
        case 'invalid-password':
            errorMessage = `Login Failed: ${message}`;
            break;
        default:
            errorMessage = `ERROR: ${message}`;
            break;
    }
    return of(AuthActions.authenticateFail({ errorMessage }));
};

@Injectable()
export class AuthEffects {
    constructor(
        private actions$: Actions,
        private router: Router,
        private stateStorageService: BrowserStorageService,
        private authHttpSvc: AuthHttpService,
    ) {}

    authLogin$ = createEffect(() =>
        this.actions$.pipe(
            ofType(AuthActions.loginStart),
            switchMap((payload) => {
                return this.authHttpSvc.sendLoginData(payload).pipe(
                    tap((data) => this.authHttpSvc.handleTimer(data)),
                    map((data) => this.handleAuth(data)),
                    catchError(handleAuthError),
                );
            }),
        ),
    );

    authRefresh$ = createEffect(() =>
        this.actions$.pipe(
            ofType(AuthActions.refreshSession),
            switchMap(() =>
                this.authHttpSvc.refreshSession().pipe(
                    map((data) => this.handleRefresh(data)),
                    catchError(handleAuthError),
                ),
            ),
        ),
    );

    authAutoLogin$ = createEffect(() =>
        this.actions$.pipe(
            ofType(AuthActions.autoLogin),
            switchMap(() => {
                const userData: AuthResponseData = this.stateStorageService.getAuthFromEither();
                if (!userData || !userData.cookie_token) {
                    return of(AuthActions.autoLoginAbort());
                }
                const { cookie } = new User(userData);
                return this.authHttpSvc.autoLogin(cookie).pipe(
                    tap((data) => this.authHttpSvc.handleTimer(data)),
                    map((data) => this.handleAutoLogin(data)),
                    catchError(handleAuthError),
                );
            }),
        ),
    );

    authLogout$ = createEffect(
        () =>
            this.actions$.pipe(
                ofType(AuthActions.logout),
                tap(() => {
                    this.authHttpSvc.clearRefreshTimer();
                    this.stateStorageService.clearAllAuth();
                    return this.router.navigate(['/sign-in']);
                }),
            ),
        { dispatch: false },
    );

    authRedirect$ = createEffect(
        () =>
            this.actions$.pipe(
                ofType(AuthActions.authenticateSuccess),
                tap(() => {
                    const prevUrl = this.stateStorageService.getUrl();
                    const dest = prevUrl.length > 0 ? prevUrl : '';
                    return this.router.navigate([dest]);
                }),
            ),
        { dispatch: false },
    );

    refreshFinished = createEffect(
        () => this.actions$.pipe(ofType(AuthActions.refreshSuccess)),
        { dispatch: false },
    );

    handleAuth(userData: AuthResponseData) {
        this.stateStorageService.setAuthToPersistent(userData);
        return AuthActions.authenticateSuccess({ authRes: userData });
    }

    handleRefresh(userData: AuthResponseData) {
        this.stateStorageService.setAuthToPersistent(userData);
        return AuthActions.refreshSuccess();
    }

    handleAutoLogin(userData: AuthResponseData) {
        this.stateStorageService.setAuthToPersistent(userData);
        return AuthActions.authenticateSuccess({ authRes: userData });
    }
}

<div style="padding-top: 10pt">
    <div class="row">
        <div class="col-md-2"></div>
        <div class="col-md-8">
            <h4>Error</h4>

            <div *ngIf="errorMessage">
                <div class="alert alert-danger">{{ errorMessage }}</div>
            </div>
        </div>
        <div class="col-md-2"></div>
    </div>
</div>

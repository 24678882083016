import { DatePipe, registerLocaleData } from '@angular/common';
import { Title } from '@angular/platform-browser';
import { FaIconLibrary } from '@fortawesome/angular-fontawesome';
import { CookieService } from 'ngx-cookie-service';
import { NgxWebstorageModule } from 'ngx-webstorage';
import { AuthInterceptorService } from '../auth/services/auth-interceptor.service';
import { ErrorHandlerInterceptor } from './interceptors/errorhandler.interceptor';
import { HTTP_INTERCEPTORS } from '@angular/common/http';
import locale from '@angular/common/locales/en';
import { fontAwesomeIcons } from './icons/font-awesome-icons';
import { NgModule, LOCALE_ID } from '@angular/core';

@NgModule({
    imports: [
        NgxWebstorageModule.forRoot({
            prefix: 'vgi',
            separator: '_',
        }),
    ],
    providers: [
        Title,
        CookieService,
        DatePipe,
        {
            provide: LOCALE_ID,
            useValue: 'en',
        },
        {
            provide: HTTP_INTERCEPTORS,
            useClass: AuthInterceptorService,
            multi: true,
        },
        {
            provide: HTTP_INTERCEPTORS,
            useClass: ErrorHandlerInterceptor,
            multi: true,
        },
    ],
})
export class CoreModule {
    constructor(iconLibrary: FaIconLibrary) {
        registerLocaleData(locale);
        iconLibrary.addIcons(...fontAwesomeIcons);
    }
}

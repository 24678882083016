import { Injectable } from '@angular/core';
import {
    HttpInterceptor,
    HttpRequest,
    HttpHandler,
    HttpEvent,
} from '@angular/common/http';
import { Observable } from 'rxjs';
import { environment } from '../../../environments/environment';
import { BrowserStorageService } from '../../core/services/browser-storage.service';
import { AuthResponseData } from '../auth-response-data.model';
import { User } from '../user.model';

@Injectable({
    providedIn: 'root',
})
export class AuthInterceptorService implements HttpInterceptor {
    constructor(private storageSvc: BrowserStorageService) {}

    intercept(
        req: HttpRequest<never>,
        next: HttpHandler,
    ): Observable<HttpEvent<unknown>> {
        const { url } = req;

        const needsAuth =
            url?.startsWith(environment.apiUrl) && !url?.includes('sign-in');

        const data: AuthResponseData = this.storageSvc.getAuthFromEither();

        if (needsAuth && data) {
            const { sessionToken } = new User(data);
            if (sessionToken) {
                const authHdr = {
                    Authorization: `Bearer ${sessionToken}`,
                };
                const modReq = req.clone({
                    setHeaders: authHdr,
                });
                return next.handle(modReq);
            }
        }
        return next.handle(req);
    }
}

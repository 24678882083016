import { select, Store } from '@ngrx/store';
import {
    Component,
    OnInit,
    ComponentFactoryResolver,
    OnDestroy,
} from '@angular/core';
import { NgForm } from '@angular/forms';
import { Observable, Subscription } from 'rxjs';
import * as fromApp from '../app.reducer';
import * as AuthActions from './store/auth.actions';
import { AppState } from '../app.reducer';
import { selectAuthError, selectAuthLoadingState } from './store/auth.reducer';

@Component({
    selector: 'app-auth',
    templateUrl: './auth.component.html',
    styles: [
        `
            button {
                margin-top: 1em;
            }
        `,
    ],
})
export class AuthComponent implements OnInit {
    loading$: Observable<boolean>;
    error$: Observable<string>;

    constructor(
        private componentFactoryResolver: ComponentFactoryResolver,
        private store: Store<AppState>,
    ) {}

    ngOnInit(): void {
        this.loading$ = this.store.pipe(select(selectAuthLoadingState));
        this.error$ = this.store.pipe(select(selectAuthError));
    }

    onSubmit(form: NgForm): void {
        if (!form.valid) {
            return;
        }
        this.onHandleError();
        const { username, password } = form.value;
        // checkbox value will be the empty string if false?
        const checked = form.value.remember_me === true;

        this.store.dispatch(
            AuthActions.loginStart({ username, password, remember_me: checked }),
        );

        form.reset();
    }

    onHandleError(): void {
        this.store.dispatch(AuthActions.clearError());
    }
}

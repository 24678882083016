import {
    faUser,
    faSort,
    faSortUp,
    faSortDown,
    faSync,
    faEye,
    faBan,
    faTimes,
    faArrowLeft,
    faSave,
    faPlus,
    faPencilAlt,
    faBars,
    faThList,
    faUserPlus,
    faRoad,
    faTachometerAlt,
    faHeart,
    faList,
    faBell,
    faBook,
    faHdd,
    faFlag,
    faWrench,
    faLock,
    faCloud,
    faSignOutAlt,
    faSignInAlt,
    faCalendarAlt,
    faSearch,
    faTrashAlt,
    faAsterisk,
    faTasks,
    faHome,
    faFilter,
    faTools,
    faToolbox,
    faCheck,
    faPrint,
    faUpload,
    faEnvelope,
    faExclamation,
    faShippingFast,
    faChevronLeft,
    faCopy,
} from '@fortawesome/free-solid-svg-icons';

export const fontAwesomeIcons = [
    faUser,
    faSort,
    faSortUp,
    faSortDown,
    faSync,
    faEye,
    faBan,
    faTimes,
    faArrowLeft,
    faSave,
    faPlus,
    faPencilAlt,
    faBars,
    faHome,
    faThList,
    faUserPlus,
    faRoad,
    faTachometerAlt,
    faHeart,
    faList,
    faBell,
    faTasks,
    faBook,
    faHdd,
    faFlag,
    faWrench,
    faLock,
    faCloud,
    faSignOutAlt,
    faSignInAlt,
    faCalendarAlt,
    faSearch,
    faTrashAlt,
    faAsterisk,
    faFilter,
    faTools,
    faToolbox,
    faCheck,
    faPrint,
    faUpload,
    faEnvelope,
    faExclamation,
    faShippingFast,
    faChevronLeft,
    faCopy,
];

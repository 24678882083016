import { Routes } from '@angular/router';

import { ErrorComponent } from './error.component';

export const errorRoutes: Routes = [
    {
        path: 'error',
        component: ErrorComponent,
        data: {
            pageTitle: 'Error',
        },
    },
    {
        path: 'access-denied',
        component: ErrorComponent,
        data: {
            pageTitle: 'Denied!',
            errorMessage: 'You are not authorized to view this page.',
        },
    },
    {
        path: 'not-found',
        component: ErrorComponent,
        data: {
            pageTitle: 'Not Found',
            errorMessage: 'Page not found.',
        },
    },
    {
        path: '**',
        redirectTo: '/not-found',
    },
];

import { createAction, props } from '@ngrx/store';
import { AuthResponseData } from '../auth-response-data.model';

export const loginStart = createAction(
    '[Auth] Login Start',
    props<{
        username: string;
        password: string;
        remember_me: boolean;
    }>(),
);

export const authenticateSuccess = createAction(
    '[Auth] Authenticate Success',
    props<{
        authRes: AuthResponseData;
    }>(),
);

export const refreshSession = createAction('[Auth] Refresh Session Start');

export const refreshSuccess = createAction('[Auth] Refresh Session Success');

export const authenticateFail = createAction(
    '[Auth] Authenticate Fail',
    props<{
        errorMessage: string;
    }>(),
);

export const clearError = createAction('[Auth] Clear Error');

export const autoLogin = createAction('[Auth] Auto/Cookie Login');

export const autoLoginAbort = createAction('[Auth] Auto/Cookie Abort');

export const autoLoginSuccess = createAction('[Auth] Auto/Cookie Login Success');

export const logout = createAction('[Auth] Logout');

<nav class="navbar navbar-expand-md navbar-light bg-white" [ngSwitch]="isAuthed$ | async">
  <a [routerLink]="['']">
    <img class="navbar-brand nav-logo" alt="VGI logo" src="assets/images/logos/vgi_logo.svg">
  </a>

  <button class="navbar-toggler" type="button" (click)="isCollapsed = !isCollapsed">
    <span class="navbar-toggler-icon"></span>
  </button>

  <div class="collapse navbar-collapse" [ngbCollapse]="isCollapsed">
    <ul class="navbar-nav mr-auto">
      <li *ngSwitchCase="true" ngbDropdown class="nav-item dropdown pointer" display="dynamic">
        <a class="nav-link dropdown-toggle"
           ngbDropdownToggle
           id="entity-menu"
           style="cursor: pointer">
                    <span>
                        <fa-icon icon="tools"></fa-icon>
                        <span>&nbsp; Toolbox</span>
                    </span>
        </a>
        <ul class="dropdown-menu" ngbDropdownMenu>
          <li class="dropdown-item">
            <a *appHasAnyPermission="['PRINERGY']"
               class="nav-link"
               routerLinkActive="active"
               [routerLink]="['/pages', 'prinergy']">
              Prinergy Print
            </a>
          </li>

          <li class="dropdown-item">
            <a *appHasAnyPermission="['EMBED_LOGO']"
               class="nav-link"
               routerLinkActive="active"
               [routerLink]="['/pages', 'embed-logo']">
              Embed Logo
            </a>
          </li>

          <li class="dropdown-item">
            <a *appHasAnyPermission="['POLITICAL_VALIDATOR']"
               class="nav-link"
               routerLinkActive="active"
               [routerLinkActiveOptions]="{ exact: false }"
               [routerLink]="['/pages', 'postie']">
              Postie
            </a>
          </li>
          <li class="dropdown-item">
            <a *appHasAnyPermission="['POLITICAL_VALIDATOR']"
               class="nav-link"
               routerLinkActive="active"
               [routerLinkActiveOptions]="{ exact: false }"
               [routerLink]="['/pages', 'political-validator']">
              Political Validator
            </a>
          </li>
          <li class="dropdown-item">
            <a *appHasAnyPermission="['PALLET_REPORT']"
               class="nav-link"
               routerLinkActive="active"
               [routerLinkActiveOptions]="{ exact: false }"
               [routerLink]="['/pages', 'pallet-report']">
              Pallet Report
            </a>
          </li>
          <li class="dropdown-item">
            <a *appHasAnyPermission="['ALERTS']"
               class="nav-link"
               routerLinkActive="active"
               [routerLinkActiveOptions]="{ exact: false }"
               [routerLink]="['/pages', 'alerts-system']">
              Alerts
            </a>
          </li>
          <li class="dropdown-item">
            <a *appHasAnyPermission="['ALERTS']"
               class="nav-link"
               routerLinkActive="active"
               [routerLinkActiveOptions]="{ exact: false }"
               [routerLink]="['/pages', 'cryptool']">
              Cryptool
            </a>
          </li>
          <li class="dropdown-item">
            <a *appHasAnyPermission="['ALERTS']"
               class="nav-link"
               routerLinkActive="active"
               [routerLinkActiveOptions]="{ exact: false }"
               [routerLink]="['/pages', 'barcoder']">
              Barcoder
            </a>
          </li>
        </ul>
      </li>
    </ul>
    <ul class="navbar-nav ml-auto">
      <li class="navbar-item" *ngSwitchCase="true">
        <a class="nav-link" (click)="logout()" style="cursor: pointer;">
          <fa-icon icon="sign-out-alt"></fa-icon>
          <span style="padding-left: 5pt">{{ (user$ | async)?.username }}</span>
        </a>
      </li>
      <li class="navbar-item" *ngSwitchCase="false">
        <a class="nav-link"
           [routerLink]="['sign-in']"
           routerLinkActive="active">
          <fa-icon icon="sign-in-alt"></fa-icon>
          <span style="padding-left: 5px">Sign In</span>
        </a>
      </li>
    </ul>
  </div>
</nav>

import { Injectable } from '@angular/core';
import { LocalStorageService, SessionStorageService } from 'ngx-webstorage';
import { AuthResponseData } from '../../auth/auth-response-data.model';

const TOKEN_KEY = 'userData';

@Injectable({ providedIn: 'root' })
export class BrowserStorageService {
    private previousUrlKey = 'previousUrl';

    constructor(
        private $sessionStorage: SessionStorageService,
        private $localStorage: LocalStorageService,
    ) {}

    storeUrl(url: string): void {
        this.$sessionStorage.store(this.previousUrlKey, url);
    }

    getUrl(): string | null | undefined {
        return this.$sessionStorage.retrieve(this.previousUrlKey);
    }

    clearUrl(): void {
        this.$sessionStorage.clear(this.previousUrlKey);
    }

    getFromEither(key: string): any {
        return (
            this.$localStorage.retrieve(key) || this.$sessionStorage.retrieve(key)
        );
    }

    getAuthFromEither(): any {
        return this.getFromEither(TOKEN_KEY);
    }

    clearBoth(key: string): void {
        this.$localStorage.clear(key);
        this.$sessionStorage.clear(key);
    }

    clearAllAuth(): void {
        this.clearBoth(TOKEN_KEY);
    }

    setPersistent(key: string, value: any): void {
        this.$localStorage.store(key, value);
    }

    setAuthToPersistent(data: AuthResponseData): void {
        this.setPersistent(TOKEN_KEY, data);
    }

    setSession(key: string, value: any): void {
        this.$sessionStorage.store(key, value);
    }

    setAuthToSession(data: AuthResponseData): void {
        this.setSession(TOKEN_KEY, data);
    }
}

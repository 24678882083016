import { NgModule } from '@angular/core';
import { HasAnyPermissionDirective } from './directives/has-any-permission.directive';
import { SharedLibsModule } from './shared-libs.module';
import { LoadingSpinnerComponent } from './loading-spinner/loading-spinner.component';
import { SubmitSpinnerComponent } from './submit-spinner/submit-spinner.component';

@NgModule({
    imports: [SharedLibsModule],
    declarations: [
        HasAnyPermissionDirective,
        LoadingSpinnerComponent,
        SubmitSpinnerComponent,
    ],
    exports: [
        SharedLibsModule,
        HasAnyPermissionDirective,
        LoadingSpinnerComponent,
        SubmitSpinnerComponent,
    ],
})
export class SharedModule {}

import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { headerNavRoute } from './layout/header-nav/header-nav.route';
import { errorRoutes } from './layout/error/errorRoutes';
import { AuthComponent } from './auth/auth.component';

const routes: Routes = [
    { path: '', redirectTo: 'pages', pathMatch: 'full' },
    {
        path: 'pages',
        loadChildren: () =>
            import('./pages/pages.module').then((m) => m.PagesModule),
    },
    // AUTH MODULE DELIBERATELY EAGERLY LOADED.
    // will be used immediately and all the time.
    {
        path: 'sign-in',
        component: AuthComponent,
        data: {
            pageTitle: 'Sign In',
        },
    },
    headerNavRoute,
    ...errorRoutes,
];

@NgModule({
    imports: [RouterModule.forRoot(routes, { useHash: true })],
    exports: [RouterModule],
})
export class AppRoutingModule {}

<div class="row">
  <div *ngIf="(loading$ | async)"
       class="col-12 mx-auto" style="text-align: center; margin-top: 80px;">
    <app-loading-spinner></app-loading-spinner>
  </div>
  <div *ngIf="!(loading$ | async)"
       class="card col-sm-10 col-md-6 col-lg-5 mx-auto" style="margin-top: 40px;">
    <div class="card-body">
      <form #f="ngForm" (ngSubmit)="onSubmit(f)">
        <div *ngIf="error$ | async as error" class="row">
          <div class="col-md-12">
            <ngb-alert [dismissible]="false" type="danger">
              {{ error }}
            </ngb-alert>
          </div>
        </div>
        <div class="form-group">
          <label for="emailInput">Username or Email</label>
          <input type="text" id="emailInput" class="form-control"
                 ngModel name="username"
                 required/>
        </div>
        <div class="form-group">
          <label for="passwordInput">Password</label>
          <input type="password" id="passwordInput" class="form-control"
                 ngModel name="password"
                 required minlength="6" />
        </div>
        <div class="form-check">
          <label class="form-check-label" for="rememberMeInput">
            <input class="form-check-input" type="checkbox"
                   ngModel name="remember_me"
                   id="rememberMeInput">
            <span>Remember Me</span>
          </label>
        </div>
        <div>
          <button class="btn btn-primary" type="submit" [disabled]="!f.valid">
            Sign In
          </button>
        </div>
      </form>
    </div>
  </div>
</div>

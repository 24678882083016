import {
    createFeatureSelector,
    createReducer,
    createSelector,
    on,
} from '@ngrx/store';
import { User } from '../user.model';
import * as AuthActions from './auth.actions';

export interface AuthState {
    user: User;
    authError: string;
    loading: boolean;
}

export const authReducer = createReducer<AuthState>(
    {
        user: null,
        authError: null,
        loading: false,
    },

    on(AuthActions.loginStart, (state) => ({
        ...state,
        authError: null,
        loading: true,
    })),

    on(AuthActions.authenticateSuccess, (state, action) => ({
        ...state,
        authError: null,
        loading: false,
        user: new User(action.authRes),
    })),

    on(AuthActions.authenticateFail, (state, action) => ({
        ...state,
        user: null,
        authError: action.errorMessage,
        loading: false,
    })),

    on(AuthActions.logout, (state) => ({
        ...state,
        user: null,
    })),

    on(AuthActions.clearError, (state) => ({
        ...state,
        authError: null,
    })),
);

export const selectAuthState = createFeatureSelector<AuthState>('auth');

export const selectAuthLoadingState = createSelector(
    selectAuthState,
    (s) => s.loading,
);

export const selectAuthError = createSelector(selectAuthState, (s) => s.authError);

export const selectAuthUser = createSelector(selectAuthState, (s) => s.user);

export const selectIsAuthedState = createSelector(selectAuthState, (s) => !!s.user);

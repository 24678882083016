import { AuthResponseData } from './auth-response-data.model';

export class User {
    public id: number;
    public allowedSystems: number;
    public email: string;
    public username: string;
    public cookie: string;
    public refreshTokenSeconds: number;
    public sessionExpiresOn: number;
    public sessionToken: string;

    constructor(authData: AuthResponseData) {
        if (authData) {
            const { member } = authData;
            this.id = member?.id;
            this.allowedSystems = member?.allowed_systems;
            this.email = member?.email_address;
            this.username = member?.username;

            this.cookie = authData.cookie_token;
            this.refreshTokenSeconds = authData.refresh_token_seconds;
            this.sessionExpiresOn = authData.session_expires_on;
            this.sessionToken = authData.session_token;
        }
    }

    get permissions(): string[] {
        return User.mapAllowedSystems(this.allowedSystems);
    }

    private static mapAllowedSystems(allowedInt: number): string[] {
        const res: string[] = [];

        if (allowedInt === 0) {
            res.push('PRINERGY');
            // comment out to test missing auth
            res.push('POLITICAL_VALIDATOR');
            res.push('EMBED_LOGO');
            res.push('PALLET_REPORT');
            res.push('ALERTS');
        }

        return res;
    }
}

// Full sign in with password payload:
// {
//     "cookie_token":null,
//     "member": {
//         "id":4,
//         "allowed_systems":0,
//         "deleted_on":null,
//         "email_address":"mitchell.sullivan@visiongraphics-inc.com",
//         "username":"mitchell.sullivan"
// },
//     "refresh_token_seconds":150,
//     "session_expires_on":1600996029726,
//     "session_token":"MoY3rHh8f+YAAAF0wsn1/g==x7fe3d26dc464fb612427a136650a66e4672e7c44fcf1bc846ef20d087908d051"
// }

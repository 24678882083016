import { Component, OnInit } from '@angular/core';
import { Title } from '@angular/platform-browser';
import {
    Router,
    ActivatedRouteSnapshot,
    NavigationEnd,
    NavigationError,
    NavigationStart,
    NavigationCancel,
} from '@angular/router';
import { Store } from '@ngrx/store';
import { BrowserStorageService } from './core/services/browser-storage.service';
import { AppState } from './app.reducer';
import { AuthActions } from './auth/store';

@Component({
    selector: 'app-root',
    template: `
        <div>
            <app-header-nav></app-header-nav>
        </div>
        <div class="container-fluid h-100">
            <div
                *ngIf="loading"
                class="col-12 mx-auto text-center"
                style="margin-top: 80px;"
            >
                <app-loading-spinner></app-loading-spinner>
            </div>
            <router-outlet *ngIf="!loading"></router-outlet>
        </div>
    `,
})
export class AppComponent implements OnInit {
    loading = false;

    constructor(
        private titleService: Title,
        private stateStorageService: BrowserStorageService,
        private router: Router,
        private store: Store<AppState>,
    ) {}

    ngOnInit(): void {
        this.router.events.subscribe((event) => {
            switch (true) {
                case event instanceof NavigationStart: {
                    this.loading = true;
                    break;
                }
                case event instanceof NavigationEnd: {
                    this.loading = false;
                    this.updateTitle();
                    if (!this.router.url.includes('sign-in')) {
                        this.stateStorageService.storeUrl(this.router.url);
                    }
                    break;
                }
                case event instanceof NavigationCancel: {
                    this.loading = false;
                    break;
                }
                case event instanceof NavigationError: {
                    if ((event as NavigationError).error.status === 404) {
                        this.router.navigate(['not-found']).then();
                    }
                    break;
                }
                default: {
                    break;
                }
            }
        });

        this.store.dispatch(AuthActions.autoLogin());
    }

    private getPageTitle(routeSnapshot: ActivatedRouteSnapshot): string {
        let title: string = routeSnapshot.data?.pageTitle || 'Tools';
        if (routeSnapshot?.firstChild) {
            title = this.getPageTitle(routeSnapshot.firstChild) || title;
        }
        return title;
    }

    private updateTitle(): void {
        const pageTitle = this.getPageTitle(this.router.routerState.snapshot.root);
        this.titleService.setTitle(pageTitle);
    }
}

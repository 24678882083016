import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { NgbDatepickerModule, NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { CommonModule } from '@angular/common';

@NgModule({
    exports: [
        FormsModule,
        CommonModule,
        NgbModule,
        NgbDatepickerModule,
        FontAwesomeModule,
        ReactiveFormsModule,
    ],
})
export class SharedLibsModule {}

import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { StoreModule } from '@ngrx/store';
import { HeaderNavComponent } from './layout/header-nav/header-nav.component';
import { CoreModule } from './core/core.module';
import { SharedModule } from './shared/shared.module';
import { ErrorComponent } from './layout/error/error.component';
import { StoreDevtoolsModule } from '@ngrx/store-devtools';
import { HttpClientModule } from '@angular/common/http';
import { appReducer, metaReducers } from './app.reducer';
import { environment } from '../environments/environment';
import { EffectsModule } from '@ngrx/effects';
import { AuthModule } from './auth/auth.module';

@NgModule({
    declarations: [AppComponent, HeaderNavComponent, ErrorComponent],
    imports: [
        BrowserModule,
        HttpClientModule,
        SharedModule,
        CoreModule,
        AppRoutingModule,
        AuthModule,
        EffectsModule.forRoot(),
        StoreModule.forRoot(appReducer, {
            metaReducers,
            runtimeChecks: {
                strictStateImmutability: true,
                strictActionImmutability: true,
                // strictStateSerializability: true,
                // strictActionSerializability: true,
            },
        }),
        StoreDevtoolsModule.instrument({
            maxAge: 25,
            logOnly: environment.production,
        }),
    ],
    bootstrap: [AppComponent],
})
export class AppModule {}
